<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible()"
        route-name="contentBlock_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-filter></app-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <div v-if="callingAPI === false && list.length == 0" class="m-t-10">
            <p class="alert alert-info">
              {{ $t('result_not_found') }}
            </p>
          </div>
          <div class="table-responsive" v-if="list.length > 0">
            <table id="list-table" class="table table-striped">
              <thead>
              <tr>
                <th class="hidden-sm-down">
                  {{ $t("contentBlock.list.id") }}
                </th>
                <th width="200">
                  {{ $t("contentBlock.list.title") }}
                </th>
                <th>
                  {{ $t("contentBlock.list.type") }}
                </th>
                <th>
                  {{ $t("contentBlock.list.identifier") }}
                </th>
                <th class="hidden-sm-down">
                  {{ $t("contentBlock.list.hidden") }}
                </th>
                <th class="hidden-sm-down" width="80">
                  {{ $t("contentBlock.list.number_of_items") }}
                </th>
                <th width="60">
                  {{ $t("contentBlock.list.external_fallback") }}
                </th>
                <th class="hidden-sm-down" width="80">
                  {{ $t("contentBlock.list.internal_fallback") }}
                </th>
                <th class="hidden-sm-down" width="450">
                  <br>Fallback settings
                </th>
                <th class="hidden-sm-down">
                  {{ $t("contentBlock.list.position") }}
                </th>
                <th class="hidden-sm-down" width="200">
                  {{ $t("contentBlock.list.created_at") }}
                </th>
                <th class="hidden-sm-down" width="200">
                  {{ $t("contentBlock.list.modified_at") }}
                </th>
                <th width="180">
                  {{ $t("actions") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(contentBlock, index) in list" :key="`item-${index}`">
                <td class="hidden-sm-down">
                  {{ contentBlock.id }}
                </td>
                <td>
                  {{ contentBlock.title }}
                </td>
                <td>
                  {{ contentBlock.type }}
                </td>
                <td>
                  {{ contentBlock.identifier }}
                </td>
                <td class="hidden-sm-down">
                  <i v-if="contentBlock.hidden" class="fas fa-check-circle"></i>
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.numberOfItems }}
                </td>
                <td>
                  <i v-if="contentBlock.externalFallback" class="fas fa-check-circle"></i>
                </td>
                <td class="hidden-sm-down">
                  <i v-if="contentBlock.internalFallback" class="fas fa-check-circle"></i>
                </td>
                <td class="hidden-sm-down">
                  <div v-if="contentBlock.fallbackSite">
                    <small>
                      <strong>{{ $t('contentBlock.fallback_site') }}</strong>
                    </small>
                    <br>
                    <span v-if="contentBlock.fallbackSite" class="label label-info">
                                            {{ siteNameBySiteId(contentBlock.fallbackSite) }}
                                        </span>
                  </div>

                  <div v-if="contentBlock.fallbackRubrics.length > 0">
                    <small>
                      <strong>{{ $t('contentBlock.fallback_rubrics') }}</strong>
                    </small>
                    <template v-for="(fallbackRubric, index) in contentBlock.fallbackRubrics">
                      <br :key="`itemx-${index}`">
                      <span :key="`item-${index}`" class="label label-info">
                        {{ siteNameByRubricId(fallbackRubric) }} / {{ rubricTitleById(fallbackRubric) }}
                      </span>
                    </template>
                  </div>

                  <div v-if="contentBlock.fallbackCategories.length > 0">
                    <small>
                      <strong>{{ $t('contentBlock.fallback_categories') }}</strong>
                    </small>
                    <template v-for="(category, index) in contentBlock.fallbackCategories">
                      <br :key="`itemX-${index}`">
                      <span :key="`item-${index}`" class="label label-inverse">
                        {{ categoryTitleById(category) }}
                      </span>
                    </template>
                  </div>

                  <div v-if="isFallbackArticleType(contentBlock)">
                    <small>
                      <strong>{{ $t('topic.article_types') }}</strong>
                    </small>
                    <br>
                    <span class="label label-inverse" v-if="contentBlock.fallbackArticleType.article">
                      {{ $t('article.article') }}
                    </span>
                    <span class="label label-inverse" v-if="contentBlock.fallbackArticleType.external">
                      {{ $t('article.article_type.external') }}
                    </span>
                    <span class="label label-inverse" v-if="contentBlock.fallbackArticleType.video">
                      {{ $t('article.article_type.video') }}
                    </span>
                    <span class="label label-inverse" v-if="contentBlock.fallbackArticleType.quiz">
                      {{ $t('article.article_type.quiz') }}
                    </span>
                    <span class="label label-inverse" v-if="contentBlock.fallbackArticleType.photostory">
                      {{ $t('article.article_type.photostory') }}
                    </span>
                  </div>

                  <div v-if="contentBlock.fallbackFlag && (contentBlock.fallbackFlag.video || contentBlock.fallbackFlag.imageGallery)">
                    <small>
                      <strong>{{ $t('article.flags') }}</strong>
                    </small>
                    <br>
                    <span class="label label-warning" v-if="contentBlock.fallbackFlag.video">
                      {{ $t('article.article_type.video') }}
                    </span>
                    <span class="label label-warning" v-if="contentBlock.fallbackFlag.imageGallery">
                      {{ $t('article.gallery') }}
                    </span>
                  </div>

                  <div v-if="contentBlock.fallbackSetting && contentBlock.fallbackSetting.prArticle">
                    <small>
                      <strong>{{ $t('article.settings') }}</strong>
                    </small>
                    <br>
                    <span class="label label-warning" v-if="contentBlock.fallbackSetting.prArticle">
                      {{ $t('article.pr') }}
                    </span>
                  </div>
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.position }}
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.createdAt | prettyDateTime }}<br>
                  {{ userNameById(contentBlock.createdBy) }}
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.modifiedAt | prettyDateTime }}<br>
                  {{ userNameById(contentBlock.modifiedBy) }}
                </td>
                <td>
                  <router-link
                    tag="a"
                    class="btn btn-default btn-sm"
                    :to="{ name: 'contentBlock_detail', params: { id: contentBlock.id }}"
                    data-test="contentBlock_list_btn_detail"
                  >
                    <i class="fa fa-info"></i>
                  </router-link>
                  <router-link
                    v-if="isEditButtonVisible(contentBlock)"
                    tag="a"
                    class="btn btn-default btn-sm"
                    :to="{ name: 'contentBlock_edit', params: { id: contentBlock.id }}"
                    data-test="contentBlock_list_btn_edit"
                  >
                    <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <p class="m-t-20">{{ $t('found') }}: {{ totalCount }}</p>
            </div>
            <div class="col-lg-6">
              <div id="editable-datatable_wrapper" class="dataTables_wrapper" v-if="list.length > 0">
                <div class="dataTables_paginate paging_simple_numbers" id="editable-datatable_paginate">
                  <app-paginate
                    v-model="currentPage"
                    :page-count="pageCount"
                    :page-range="5"
                    :margin-pages="2"
                    :click-handler="setPageAndGetRecords"
                    :prev-text="$t('page_prev')"
                    :next-text="$t('page_next')"
                    :container-class="'pagination'"
                    :prev-class="'paginate_button previous'"
                    :next-class="'paginate_button next'"
                    :page-class="'paginate_button'">
                  </app-paginate>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import Filter from '../../components/filter/FilterContentBlock'
import Paginate from 'vuejs-paginate'
import ButtonCreate from '../../components/shared/ButtonCreate'
import Config from '../../config'
import UserService from '../../services/user/UserService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'ContentBlockList',
  data () {
    return {
      currentPage: 1,
      limit: Config.contentBlock.list.limit,
      dataLoaded: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_CONTENT_BLOCK_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    list () {
      return this.$store.getters['contentBlock/list']
    },
    totalCount () {
      return this.$store.getters['contentBlock/totalCount']
    },
    page () {
      return this.$store.getters['contentBlock/page']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['contentBlock/totalCount'] / Config.contentBlock.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFilter: Filter,
    appPaginate: Paginate,
    appButtonCreate: ButtonCreate
  },
  methods: {
    hasPermission (permission, contentBlock) {
      return this.$store.getters['user/hasPermission'](permission, contentBlock?.site ?? null)
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible (contentBlock) {
      return this.hasPermission(this.requiredPermissions.editButton, contentBlock)
    },
    getList () {
      this.$store.dispatch('contentBlock/fetch')
        .then(async () => {
          const fallbackRubricIds = {}
          this.list.forEach(contentBlock => {
            contentBlock.fallbackRubrics.forEach(fallbackRubric => {
              fallbackRubricIds[fallbackRubric] = true
            })
          })
          await this.$store.dispatch('rubric/lazyLoadRubricsByIds', Object.keys(fallbackRubricIds))
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('contentBlock/setPage', page)
      this.getList()
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    rubricTitleById (rubricId) {
      return this.$store.getters['rubric/rubricById'](rubricId)?.title ?? rubricId
    },
    siteNameBySiteId (id) {
      return this.$store.getters['site/siteById'](id)?.title ?? id
    },
    siteNameByRubricId (rubricId) {
      const siteId = this.$store.getters['rubric/rubricById'](rubricId)?.site
      const site = siteId && this.$store.getters['site/siteById'](siteId)
      if (site) {
        return site.title
      }
      return siteId ?? `unknown-rubric-${rubricId}`
    },
    categoryTitleById (id) {
      return this.$store.getters['category/all'].find(category => category.id === id)?.title ?? id
    },
    isFallbackArticleType (contentBlock) {
      if (contentBlock.fallbackArticleType.article ||
        contentBlock.fallbackArticleType.external ||
        contentBlock.fallbackArticleType.video ||
        contentBlock.fallbackArticleType.quiz ||
        contentBlock.fallbackArticleType.photostory
      ) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.$store.commit('contentBlock/setLimit', Config.contentBlock.list.limit)
    this.getList()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
